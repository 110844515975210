import "vendor/front/assets/images/SVG/service_mt.svg";
import "vendor/front/assets/images/SVG/service_ll.svg";
import "vendor/front/assets/images/SVG/service_bd.svg";
import "vendor/front/assets/images/SVG/service_nc.svg";
import "vendor/front/assets/images/SVG/service_uu.svg";
import "vendor/front/assets/images/SVG/service_oo.svg";
import "vendor/front/assets/images/SVG/service_kk.svg";
import "vendor/front/assets/images/SVG/service_gn.svg";
import "vendor/front/assets/images/SVG/service_ss.svg";
import "vendor/front/assets/images/SVG/service_sl.svg";
import "vendor/front/assets/images/SVG/service_tl.svg";
import "vendor/front/assets/images/SVG/service_kt.svg";

import "animate.css";
// import "bootstrap/dist/css/bootstrap";
// import "bootstrap"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import 'magnific-popup';
import 'animsition';
import 'ionicons';
import 'smooth-scroll';
import 'wowjs';
import "vendor/front/assets/css/style.css";
import "vendor/front/assets/css/custom.scss";

// import "bootstrap";
import "jarallax";
import autosize from "autosize"
window['autosize'] = autosize

import "vendor/front/assets/js/menu.js"
import "vendor/front/assets/js/custom.ts"
import "vendor/front/assets/js/philosophy.ts"
