/* ----- Custom Scripts for Destiny template ----- */
import { WOW } from 'wowjs';
import { jarallax } from 'jarallax'
require("jquery-ui/ui/effects/effect-slide");

$(($) => {
  "use strict";

  $('#hero').outerHeight(window.innerHeight);

  var mainbottom = $('#main').offset().top;
  $(window).on('scroll',function(){
    let stop = Math.round($(window).scrollTop());
    if (stop > mainbottom) {
      $('.navbar').addClass('past-main');
      $('.navbar').addClass('effect-main')
    } else {
      $('.navbar').removeClass('past-main');
    }
  })
  $(document).on('click.nav','.navbar-collapse.in',function(e) {
    if( $(e.target).is('a') ) {
      $(this).removeClass('in').addClass('collapse');
    }
  });
  $(".testimonials").owlCarousel({
    slideSpeed : 200,
    items: 1,
    singleItem: true,
    autoPlay : true,
    pagination : false
  });
  $(".clients").owlCarousel({
    slideSpeed : 200,
    items: 5,
    singleItem: false,
    autoPlay : true,
    pagination : false
  });
  $('a.page-scroll').bind('click', function(event) {
      var $anchor = $(this);
      $('html, body').stop().animate({
          scrollTop: $($anchor.attr('href')).offset().top
      }, 1500, 'easeOutExpo');
      event.preventDefault();
  });
  $('.popup').magnificPopup({
    disableOn: 0,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,

    fixedContentPos: false
  });
  jarallax(document.querySelectorAll('.jarallax'), {
      speed: 0.7
  });
  jarallax(document.querySelectorAll('.personal-jarallax'), {
      speed: 0.7
  });
  window.addEventListener('load', () => {
    setTimeout(() => {
      $('#loading').fadeOut('slow', () => {});
    });
  })
  new WOW().init();
  $(window).scroll(() => {
    if ($(this).scrollTop() > 1000) {
      $('#back-top').fadeIn();
    } else {
      $('#back-top').fadeOut();
    }
  })
  $('#back-top').on('click', function () {
    $('#back-top').tooltip('hide');
    $('body,html').animate({scrollTop: 0}, 1500);
    return false;
  });
  $(".animsition").animsition({
    inClass: 'fade-in',
    outClass: 'fade-out',
    inDuration: 1500,
    outDuration: 800,
    linkElement: '.animsition-link',
    // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
    loading: true,
    loadingParentElement: 'body', //animsition wrapper element
    loadingClass: 'animsition-loading',
    loadingInner: '', // e.g '<img src="loading.svg" />'
    timeout: false,
    timeoutCountdown: 5000,
    onLoadEvent: true,
    browser: [ 'animation-duration', '-webkit-animation-duration'],
    // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
    // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
    overlay : false,
    overlayClass : 'animsition-overlay-slide',
    overlayParentElement : 'body',
    transition: function(url){ window.location.href = url; }
  });
})
